import React, { Component } from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import styles from "./kontakt.module.css";
import { FaPhone } from "react-icons/fa";
import SEO from "../components/seo";
import Hero from "../components/hero";
import ContactForm from "../components/ContactForm";

class Kontakt extends Component {
  state = {
    sending: false
  };
  render() {
    return (
      <Layout>
        <SEO
          title="Kontakt - Prosty system CRM"
          description="Kontakt • Mobilny program CRM online • Łatwy w obsłudze • Wypróbuj demo i przekonaj się, że prosty CRM może mieć wiele możliwości!"
        />
        <Hero maxHeight="280px" justifyContent="center">
          <h1>Kontakt</h1>
          <p>Masz pytania? Skontaktuj się z nami</p>
        </Hero>
        <Container className={styles.wrapper}>
          <div className={styles.formWrapper}>
            <ContactForm systemType="quickcrmpro" />
          </div>
          <div className={styles.mapContainer}>
            <div className={styles.contactHeader}>
              <FaPhone size="50px" />
              <p>
                <a
                  className={styles.phoneNum}
                  href="tel:+48506504540"
                  title="+48 506 504 540"
                >
                  +48 506 504 540
                </a>
              </p>
              <p>
                Pracujemy od poniedziałku do piątku <br />w godzinach 8 — 16
              </p>
            </div>
            <iframe
              title="Adres Questy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.608052588435!2d16.913769930151982!3d52.413779407663064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b4b2800c3e7%3A0x5336529b5602e3de!2sQuesty!5e0!3m2!1spl!2spl!4v1488363170109"
              // style="border: 0px none; pointer-events: none;"
              allowfullscreen=""
              width="400"
              height="300"
              frameborder="0"
            />
          </div>
        </Container>
      </Layout>
    );
  }
}

export default Kontakt;
