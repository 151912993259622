export const contactFormStrings = {
  FILL_ALL_FIELDS_ERROR: "Wypełnij wszystkie wymagane pola.",
  NAME_PLACEHOLDER: "Imię i nazwisko",
  COMPANY_NAME_PLACEHOLDER: "Nazwa firmy",
  COMPANY_EMAIL_PLACEHOLDER: "Email firmowy",
  COMPANY_PHONE_PLACEHOLDER: "Telefon",
  CONTENT_PLACEHOLDER: "Treść wiadomości",
  COMPANY_SIZE_SELECT_DEF_NAME: "Wielkość firmy",
  COMPANY_SIZE_1_NAME: "Działalność jednoosobowa",
  COMPANY_SIZE_2_NAME: "Mikro (do 10 pracowników)",
  COMPANY_SIZE_3_NAME: "Mała (do 49 pracowników)",
  COMPANY_SIZE_4_NAME: "Średnia (do 249 pracowników)",
  COMPANY_SIZE_5_NAME: "Duża (250 pracowników lub więcej)",
  CONSENT_SHORT: "Wyrażam zgodę na przetwarzanie danych osobowych",
  CONSENT_LONG: `Wyrażam zgodę na przetwarzanie przez Questy – Wdrożenia i Wsparcie IT
  Sp. z o.o. z siedzibą w Poznaniu, ul. Głogowska 31/33, 60-702 Poznań
  moich danych osobowych zawartych w niniejszym formularzu, w celu
  otrzymania odpowiedzi na zadane pytanie. Twoje dane będą przechowywane
  do momentu cofnięcia zgody. Posiadasz dostęp do przetwarzanych danych
  osobowych i ich poprawiania, a także prawo żądania zaprzestania
  przetwarzania danych, wniesienia sprzeciwu i ich usunięcia w dowolnym
  momencie bez wpływu na zgodność z prawem przetwarzania przez wysłanie
  wiadomości e-mail na adres kontakt@questy.pl z adresu, którego dotyczy
  zgoda. Udostępnienie danych osobowych jest dobrowolne, jednak odmowa
  ich przetwarzania uniemożliwia nam obsługę Twojego zgłoszenia.`,
  FORM_SENDING: "Wysyłanie...",
  FORM_SEND: "Wyślij",
};
